import React from "react"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */
class Mainimage extends React.Component {
  constructor(props) {
    super(props);

    const images = [];

    props.images.map((item, index) => (
        images.push({src: require(`../assets/${item}`)})
    ))

    this.state = {
      photos: images
    };

    this.element = React.createRef();
    this.sizer = React.createRef();
  }

  componentDidMount() {
    this._fetchPhotos()
        .then(this._whenPhotosLoaded.bind(this))
        .then((photos) => {

        });
  }

  _whenPhotosLoaded(photos) {
    return Promise.all(photos.map(photo => new Promise((resolve) => {
      const image = document.createElement('img');
      image.src = photo.src;

      if (image.naturalWidth > 0 || image.complete) {
        resolve(photo);
      } else {
        image.onload = () => {
          resolve(photo);
        };
      }
    })));
  }

  _fetchPhotos() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.state.photos);
      }, 300);
    });
  }

  render() {
    return (
        <div className="container" ref={this.element} style={{width:'100%'}}>
          {this.state.photos.map((item, index) => (
              (<div key={index} className={"photo-item"} style={{
                width: '600px',
                maxWidth: '100%',
                margin: '0 auto'
              }}>
                  <img src={item.src} alt={item.name} title={item.name} style={{marginBottom:0}} />
              </div>)
          ))}
        </div>
    );
  }
}

export default Mainimage
