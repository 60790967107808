import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 style={{
        fontFamily: 'Montserrat, sans-serif'
    }}>NOT FOUND</h1>
    <p style={{
        fontFamily: 'Montserrat, sans-serif'
    }}>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
