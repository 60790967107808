import React from "react"
import {graphql, StaticQuery} from "gatsby";
import Mainimage from "./mainimage";
import SEO from "./seo";
import Layout from "./layout";
import Page404 from "../pages/404";

const Detail = (slug) => (
    <StaticQuery
        query={graphql`
      {
          allGalleriesJson {
            edges {
              node {
                name
                copyright
                slug
                retouch
                images
              }
            }
          }
        }
    `}
    render={data => ((renderDetail(data.allGalleriesJson.edges.find(node=>node.node.slug === slug.slug))))}
    />)

const renderDetail=value=>{
    if (!value) {
        return <Page404 />
    }

    return (
        <Layout>
            <SEO title={value.node.name} />

            <div style={{
                width: '600px',
                maxWidth: '100%',
                margin: '0 auto'
            }}>
                <h1 style={{
                    color: '#92908c',
                    fontSize: '18px',
                    textTransform: 'uppercase',
                    marginBottom: '10px',
                    fontFamily: 'Montserrat, sans-serif'
                }}>{value.node.name}</h1>
                <div style={{
                    color: '#92908c',
                    fontSize: '16px',
                    lineHeight: '18px',
                    textTransform: 'uppercase',
                    marginBottom: '20px',
                    fontFamily: 'Montserrat, sans-serif'
                }}>{value.node.copyright}</div>

                <Mainimage images={value.node.images}/>
            </div>
        </Layout>

    )

}

export default Detail